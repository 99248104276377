import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';

@Component({
  selector: 'app-warningpop-up',
  templateUrl: './warningpop-up.component.html',
  styleUrls: ['./warningpop-up.component.scss'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule],
})
export class WarningpopUpComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }) {}
}
