<h2 mat-dialog-title>Bestätigung</h2>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Schließen</button>
  <button
    mat-button
    style="color: white; background-color: red"
    [mat-dialog-close]="true"
  >
    Bestätigen
  </button>
</div>
